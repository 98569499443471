import { defineStore } from 'pinia'
import axios from 'axios'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import isBetween from 'dayjs/plugin/isBetween'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
dayjs.extend(isBetween)

import cartClient from '@/js/api/cart.js'
import { toast } from '@/js/lib/toast.js'

export const useCartStore = defineStore('cart', {
	state: () => ({
		cartItems: [],
		subtotal: 0,
		subtotalFormatted: 0,
		orderDiscountAmount: 0,
		changingQuantity: {},
		rewardModalOpen: false,
		rewardModalDetails: {},
	}),

	getters: {
		isCartEmpty: (state) => state.cartItems.length === 0,
		getKit: (state) => state.cartItems.find((item) => item.is_kit),
		itemCount: (state) => state.cartItems.length,
		quantityTotal: (state) => {
			return state.cartItems.reduce((acc, item) => acc + item.quantity, 0)
		},
		ephemoralCount: (state) => (lineItemId) => {
			return state.cartItems
				.filter((item) => lineItemId === item.line_item_id)
				.reduce((acc, curr) => acc + curr.quantity, 0)
		},
		hasItemForPaletteCreditInCart: (state) => {
			const lookup = state.cartItems.find((item) => item.discount_id?.includes('palette_credit'))
			return lookup !== undefined
		},
		cartItemIds: (state) => state.cartItems.map(({ details }) => details.itemId),
	},

	actions: {
		openRewardModal() {
			this.rewardModalOpen = true
		},
		closeRewardModal() {
			this.rewardModalOpen = false
		},
		updateModalAttributes(attributes) {
			this.rewardModalDetails = attributes
		},
		clearCart() {
			this.cartItems = []
		},
		setCartItems(cartItems) {
			this.cartItems = cartItems
		},
		setSubtotal(subtotal) {
			this.subtotal = subtotal
		},
		setSubtotalFormatted(subtotalFormatted) {
			this.subtotalFormatted = subtotalFormatted
		},
		setOrderDiscountAmount(orderDiscountAmount) {
			this.orderDiscountAmount = orderDiscountAmount
		},
		changeQuantity({ itemIndex, quantity }) {
			const cartItems = this.cartItems
			cartItems[itemIndex].quantity = quantity
			this.cartItems = cartItems
		},
		async addToCart({ itemId, quantity, isKit = 0, discountType } = {}) {
			try {
				const { data } = await cartClient.addItem(itemId, quantity, isKit, discountType)
				const {
					items,
					subtotal,
					subtotalFormatted,
					rewards: { orderDiscountAmount },
				} = data
				this.cartItems = items
				this.subtotal = subtotal
				this.subtotalFormatted = subtotalFormatted
				this.orderDiscountAmount = orderDiscountAmount
				return { data }
			} catch (error) {
				const message = error.response?.data.message || 'There was an error trying to add item(s) to your cart!'
				toast({ type: 'error', title: 'Error', message, timeout: 5000 })
				return { data: {} }
			}
		},
		async updateItemQuantity({ lineItemId, quantity, oldQuantity }) {
			let itemIndex
			try {
				if (quantity === oldQuantity) {
					return
				}
				itemIndex = this.cartItems.findIndex((item) => item.line_item_id === lineItemId)
				if (itemIndex === -1) {
					return
				}
				let newQuantity = quantity
				const ephemoralCheck = this.ephemoralCount(lineItemId)
				if (ephemoralCheck !== oldQuantity) {
					const direction = quantity - oldQuantity > 0 ? 1 : -1
					newQuantity = ephemoralCheck + direction
				}
				this.changingQuantity[lineItemId] = lineItemId
				this.changeQuantity({ itemIndex, quantity: newQuantity })

				const { data } = await cartClient.updateItem(lineItemId, newQuantity)
				const {
					items,
					subtotal,
					subtotalFormatted,
					rewards: { orderDiscountAmount },
				} = data
				this.cartItems = items
				this.subtotal = subtotal
				this.subtotalFormatted = subtotalFormatted
				this.orderDiscountAmount = orderDiscountAmount
				delete this.changingQuantity[lineItemId]
				return { data }
			} catch (error) {
				this.changeQuantity({ itemIndex, quantity: oldQuantity })
				delete this.changingQuantity[lineItemId]
			}
		},
		async removeFromCart({ lineItemId, itemCode, isLoyalty, loyaltyRemovalEndpoint }) {
			try {
				this.changingQuantity[lineItemId] = lineItemId
				if (isLoyalty) {
					const { data } = await axios.post(loyaltyRemovalEndpoint, { itemCode })
					const {
						items,
						subtotal,
						subtotalFormatted,
						rewards: { orderDiscountAmount },
					} = data
					this.cartItems = items
					this.subtotal = subtotal
					this.subtotalFormatted = subtotalFormatted
					this.orderDiscountAmount = orderDiscountAmount
					delete this.changingQuantity[lineItemId]
					return { data }
				}

				const { data } = await cartClient.removeItem(lineItemId)
				const {
					items,
					subtotal,
					rewards: { orderDiscountAmount },
				} = data
				this.cartItems = items
				this.subtotal = subtotal
				this.orderDiscountAmount = orderDiscountAmount
				delete this.changingQuantity[lineItemId]
				return { data }
			} catch (error) {
				throw new Error(error)
			}
		},
	},
})
