<template>
	<button type="button" :disabled="disabled" @click="handleCopy">
		<svg-vue icon="share-up" class="w-4 h-4 hover:scale-110" />
	</button>
</template>
<script setup>
import { ref, onMounted, computed } from 'vue'
import { storeToRefs } from 'pinia'

import { usePaletteBuilderStore, useAppStore } from '@stores'
import { toast } from '@/js/lib/toast.js'

const appStore = useAppStore()
const { isLargeMediaQuery } = storeToRefs(appStore)
const paletteBuilderStore = usePaletteBuilderStore()
const { loading } = storeToRefs(paletteBuilderStore)

const hasNativeShare = ref(false)
const hasNativeClipboard = ref(false)

const disabled = computed(() => loading.value)

onMounted(() => {
	hasNativeShare.value = !!navigator.share // note: SSL needed
	hasNativeClipboard.value = !!navigator.clipboard // note: SSL needed
})

const getShareUrl = () => {
	const shareUrl = window.location.href
	return shareUrl
}

const handleCopy = async () => {
	const url = getShareUrl()
	const text = `Check out the palette I built for you here ${url}`
	if (hasNativeShare.value && !isLargeMediaQuery.value) {
		try {
			await navigator.share({
				title: 'Palette Builder Link',
				text,
				// url, // maybe don't need since It's being sent in text version above?
			})
			return
		} catch (_) {
			modernCopy(text)
		}
	} else if (hasNativeClipboard.value) {
		// navigator clipboard - desktop safari
		try {
			await navigator.clipboard.writeText(text)
			return
		} catch (_) {
			modernCopy(text)
		}
	} else {
		modernCopy(text)
	}
}
const modernCopy = (url) => {
	if (navigator.clipboard && window.isSecureContext) {
		// Modern method: Navigator clipboard API (requires HTTPS in most browsers)
		document.activeElement.blur()
		document.body.focus()
		return navigator.clipboard
			.writeText(url)
			.then(() => {
				toast({ type: 'success', title: 'Success', message: 'Palette Link Copied!' })
			})
			.catch((err) => {
				console.error('Failed to copy text: ', err)
			})
	} else {
		// Fallback method for older browsers
		const textarea = document.createElement('textarea')
		textarea.value = url

		// Make the textarea element invisible and place it off-screen
		textarea.style.position = 'absolute'
		textarea.style.opacity = '0'

		document.body.appendChild(textarea)
		textarea.focus()
		textarea.select()

		try {
			const successful = document.execCommand('copy')
			if (successful) {
				toast({ type: 'success', title: 'Success', message: 'Palette Link Copied!' })
			} else {
				console.error('Failed to copy text (fallback)')
			}
		} catch (err) {
			console.error('Fallback: Could not copy text', err)
		} finally {
			document.body.removeChild(textarea)
		}
	}
}
</script>
