<template>
	<p
		:class="[
			'!m-0 !p-0 flex items-center gap-x-1',
			isSlider && 'justify-center',
			isSlider && isFullCover && '!text-white',
		]"
	>
		<template v-if="range">
			<span class="text-[13px]/[16px] font-medium">
				{{ range.min }}
			</span>
			-
			<span class="text-[13px]/[16px] font-medium">
				{{ range.max }}
			</span>
		</template>
		<template v-else-if="!isLoyalty">
			<span class="text-[13px]/[16px] font-medium">
				{{ price }}
			</span>
		</template>
		<span v-if="original && !range" class="text-line-through text-neutral-500 text-[11px]/[16px] font-normal">
			{{ original }}
		</span>
		<span v-if="savings && !isLoyalty && !range" class="text-[11px]/[16px] text-orange-800 font-normal">
			<template v-if="savings.isPaletteCredit">
				<span>{{ savings.amount }}</span> <span>{{ savings.discountLabel }}</span>
			</template>
			<template v-else-if="savings.isUserSelected">
				{{ savings.discountLabel }}
			</template>
			<template v-else-if="savings.isPercent"> {{ savings.amount }}% Off </template>
			<template v-else>
				<span>Save</span> <span>{{ savings.amount }}</span>
			</template>
		</span>
		<span v-if="isLoyalty" class="currency text-[11px]/[16px] !text-orange-800 font-normal">Reward</span>
	</p>
</template>
<script setup>
import { computed } from 'vue'

const props = defineProps({
	item: {
		type: Object,
		required: true,
	},
	minPrice: {
		type: Number,
		default: null,
	},
	maxPrice: {
		type: Number,
		default: null,
	},
	saleInformation: {
		type: Array,
	},
	isCartItem: {
		type: Boolean,
		default: false,
	},
	isSlider: {
		type: Boolean,
		default: false,
	},
	isFullCover: {
		type: Boolean,
		default: false,
	},
	isLoyalty: {
		type: Boolean,
		default: false,
	},
})

const sale = computed(() => {
	const saleArr = props.saleInformation ?? []
	return saleArr.find((s) => s.qualifying_skus.includes(props.item.itemCode) && s.can_purchase)
})
const range = computed(() => {
	if (props.minPrice && props.maxPrice && props.minPrice !== props.maxPrice) {
		return { min: props.minPrice, max: props.maxPrice }
	}
	return null
})
const trimDecimals = (num) => {
	if (num % 1 !== 0) {
		return num.toFixed(2)
	}
	return num.toFixed(0)
}
const price = computed(() => {
	const { priceRaw, currencySymbol } = props.item

	if (sale.value) {
		const { credit, type } = sale.value

		let discountAmount
		if (type === 'percent') {
			discountAmount = (priceRaw * credit) / 100
		} else {
			discountAmount = credit
		}

		return `${currencySymbol}${trimDecimals(Math.max(0, priceRaw - discountAmount))}`
	}

	if (props.isCartItem && props.item.discounted) {
		const { discountType, discountValue } = props.item

		let amount
		if (discountType === 'percent') {
			amount = (priceRaw * (100 - discountValue)) / 100
		} else {
			amount = priceRaw - discountValue
		}

		return `${currencySymbol}${trimDecimals(Math.max(0, amount))}`
	}

	return `${currencySymbol}${priceRaw}`
})
const original = computed(() => {
	const { isBundle, bundleValue, priceRaw, currencySymbol } = props.item

	if (props.item?.isAutoAdd) return null

	if ((props.isCartItem && props.item.discounted) || sale.value) {
		// safegaurd 0 discount case
		if (parseInt(priceRaw) === price) {
			return null
		}
		return `${currencySymbol}${trimDecimals(priceRaw)}`
	}
	if (isBundle && parseFloat(bundleValue) > 0) {
		return `${currencySymbol}${bundleValue}`
	}
	return null
})

const savings = computed(() => {
	const { isBundle, bundleValue, priceRaw, currencySymbol } = props.item

	const isPromotional = !!(props.item?.isPromotionalItem || sale.value?.is_promotion)
	const isPaletteCredit = !!(props.item?.discountId === 'palette_credit' && props.isCartItem)
	const discountLabel = isPaletteCredit ? props.item?.discountTitle : props.item?.discountText

	if (sale.value) {
		const { credit, type } = sale.value

		let discountAmount
		if (type === 'percent') {
			discountAmount = (priceRaw * credit) / 100
		} else {
			discountAmount = credit
		}

		// safegaurd 0 discount case
		if (discountAmount === 0) return null

		const isPercent = isPromotional && type === 'percent'

		let amount = !isPromotional ? `${discountAmount}` : `${trimDecimals(Math.floor(credit))}`

		if (!isPercent) {
			amount = `${currencySymbol}${amount}`
		}
		return {
			isPercent,
			isAmount: !isPromotional || type !== 'percent',
			isBundle: false,
			amount,
			isPaletteCredit,
			discountLabel,
		}
	}

	if (props.isCartItem && props.item.discounted) {
		const { discountType, discountValue, isUserSelected } = props.item

		let discountAmount
		if (discountType === 'percent') {
			discountAmount = (priceRaw * discountValue) / 100
		} else {
			discountAmount = discountValue
		}

		const isPercent = isPromotional && discountType === 'percent'
		let amount = !isPromotional ? `${discountAmount}` : `${trimDecimals(Math.floor(discountValue))}`
		if (!isPercent) {
			amount = `${currencySymbol}${amount}`
		}

		return {
			isPercent,
			isAmount: !isPromotional || discountType !== 'percent',
			isBundle: false,
			amount,
			isPaletteCredit,
			isUserSelected,
			discountLabel,
		}
	}

	if (isBundle && parseFloat(bundleValue) > 0) {
		return { isBundle, amount: `${currencySymbol}${trimDecimals(parseFloat(bundleValue) - priceRaw)}` }
	}
	return null
})
</script>
